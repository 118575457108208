import React from 'react';
import ContactSection from '../../components/ContactSection/ContactSection';

const Contact = () => (
    <div>
        <article>
            <ContactSection
                heading="Kontaktujte nás a získejte nabídku."
                subheading="Vaše designová vizitka na internetu."
                text="Naše služby spojují sílu webových stránek a sociálních sítí, čímž vytváříme jednotnou a propojenou online prezentaci. Integrace těchto dvou kanálů zaručuje, že vaše značka bude mít jasný a konzistentní hlas na všech frontách. Zaměřujeme se na jednoduchost, spolehlivost a především na to, aby výsledky přinášely skutečnou hodnotu."
            />
        </article>
    </div>
);

export default Contact;