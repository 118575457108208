import React from 'react';
import MenuSection from '../../components/MenuSection/MenuSection';
import ContentTracker from '../../components/ContentTracker/ContentTracker';

const Menu = () => (
    <div>
        <ContentTracker></ContentTracker>
        
        <MenuSection
            heading="Nové webové stránky"
            subheading="Vaše designová vizitka na internetu."
            text="Vytváříme moderní a funkční webové stránky, které reprezentují vaši značku a pomáhají dosáhnout vašich cílů. Spolehněte se na profesionální design, rychlou optimalizaci a přehlednou strukturu."
            priceText="Od 1500 Kč"
        />

        <MenuSection
            heading="Spravování sociálních sítí"
            subheading="Váš podnik v moderním světě. "
            text="Děláme tradičním podnikům dobrou vizitku na sociálních sítích, ze kterých většina mladých bere informace. Je pro nás důležité, aby Váš sociální profil vydával obsah často, ale zároveň aby byl kvalitní."
            priceText="Od 300 Kč"
            showArrow={false}
        />
    </div>
);

export default Menu;