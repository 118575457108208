import React from 'react';
import "./PriceListSectionStyles.css"
import PriceItem from '../ContactSection/PriceItem/PriceItem';

const PriceList = () => {

    return (
        <section>
            <article>
                <div className='priceList'>
                    <h2>Základní ceník</h2>

                    <div>
                        <PriceItem name="Designová stránka" price="Od 1000 Kč" />
                        <PriceItem name="Spravování soc. sítí" price="Od 300 Kč" />
                        <PriceItem name="Spravování stránky" price="Od 100 Kč" />
                        <PriceItem name="Webová aplikace" price="Od 2000 Kč" description="Web se složitější logikou, například rezervační systém pro restauraci."/>
                    </div>
                </div>
            </article>
        </section>
    );
};

export default PriceList;
