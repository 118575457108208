import React, { useState, useEffect } from 'react';
import './ContentTracker.css';

const ContentTracker = () => {
    const minOpacity = 0.2;

  const [opacity1, setOpacity1] = useState(1);
  const [opacity2, setOpacity2] = useState(minOpacity);

  useEffect(() => {
    const handleScroll = () => {
        const scrollPosition = window.scrollY;
        const documentHeight = document.body.scrollHeight;
        const halfPageHeight = documentHeight / 2;
  
        // Calculate opacity for each row, ensuring they never go below 0.2
        const row1Opacity = Math.max(minOpacity, Math.min(1, (halfPageHeight - scrollPosition) / halfPageHeight));
        const row2Opacity = Math.max(minOpacity, Math.min(1, scrollPosition / halfPageHeight));
  
        setOpacity1(row1Opacity);
        setOpacity2(row2Opacity);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="content-tracker">
      <div className="row" style={{ opacity: opacity1 }}>
        <div className="circle"></div>
        <p className="text">Webová stránka</p>
      </div>
      <div className="row" style={{ opacity: opacity2 }}>
        <div className="circle"></div>
        <p className="text">Sociální sítě</p>
      </div>
    </div>
  );
};

export default ContentTracker;
