import React from 'react';
import "./ContactSectionStyles.css"
import PriceList from '../PriceList/PriceList';

const ContactSection = ({ heading, subheading, text, }) => {

  return (
    <section>
      <article>
        <div className='contactSection'>
          <h1>{heading}</h1>
          <h2>{subheading}</h2>
          <p>{text}</p>
          <div className='sectionButtons'>
            <button onClick={() => {
              window.location.href = "mailto:jan.kubes07@gmail.com";
            }}>
              <p><b>{'Email'}</b></p>
              <p><i>jan.kubes07@gmail.com</i></p>
            </button>
            <button onClick={() => {
              window.location.href = "tel:608-197-111";
            }}>
              <p><b>{'Telefon'}</b></p>
              <p><i>608 197 111</i></p>
            </button>
          </div>
        </div>
      </article>

      <PriceList></PriceList>
    </section>
  );
};


// Default props (in case props are not provided)
ContactSection.defaultProps = {
  heading: 'Default Heading',
  subheading: 'Default Subheading',
  text: 'This is default text for the section.',
};

export default ContactSection;
