import React from 'react';
import "./WhyUsSectionStyles.css"
import { useNavigate } from 'react-router-dom';

const WhyUsSection = ({ heading, subheading, text, button1Text, priceText}) => {

  const navigate = useNavigate();

  return (
    <section>
      <article>
        <div className='whyUsSection'>
          <h1>{heading}</h1>
          <h2>{subheading}</h2>
          <div dangerouslySetInnerHTML={{__html: text}}></div>
          <div className='sectionButtons'>
            <button onClick={
              () => {
                navigate('/kontakt')
              }
            }><b>{button1Text || 'Získat nabídku'}</b></button>
          </div>
        </div>
      </article>
    </section>
  );
};


export default WhyUsSection;
