import React from 'react';
import WhyUsSection from '../../components/WhyUsSection/WhyUsSection';

const WhyUs = () => (
    <div>
        <WhyUsSection
            heading="Proč si zvolit mladou firmu?"
            subheading="Jsme mladá a dravá firma. Nebojíme se inovovat."
            text="
            <p>Vyrabíme digitalní obsah, který je jak levný, tak funkční. Umíme instagram, Facebook nebo třeba X a všechny tyhle platformy zvládneme spojit s Vaší webovou stránkou.
                <ul>
                    <li> Designová stránka od 1000 Kč. </li>
                    <li>Webová aplikace, stránka, se speciálními funkcemi, jako je třeba registrace, od 2000 Kč.</li>
                    <li> Hosting a doména od 100 Kč měsíčně.
                </ul>
            </p>"
        ></WhyUsSection>
    </div>
);

export default WhyUs;