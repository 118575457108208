import React from 'react';
import "./MenuSectionStyles.css"
import { useNavigate } from 'react-router-dom';

const MenuSection = ({ heading, subheading, text, button1Text, button2Text, priceText, showArrow }) => {

  const navigate = useNavigate();

  return (
    <section>
      <article>
        <div className='menuSection'>
          <h1>{heading}</h1>
          <h2>{subheading}</h2>
          <p>{text}</p>
          <div className='sectionButtons'>
            <button onClick={
              () => {
                navigate('/kontakt')
              }
            }><b>{button1Text || 'Získat nabídku'}</b></button>
            <button onClick={() => {
                navigate('/proc-my')
            }}>{button2Text || 'Proč my?'}</button>
            <div className='menuSectionPriceText'>
              <p>{priceText || 'Price'}</p>
            </div>
          </div>
        </div>
        {
          showArrow ?
            <div className='arrow'>
              <p className='arrowText'>Spravování</p>
              <p className='arrowText'>sociálních sítí</p>

              <div className='downArrow'>

              </div>
            </div>
            :
            <div></div>
        }
      </article>
    </section>
  );
};


// Default props (in case props are not provided)
MenuSection.defaultProps = {
  heading: 'Default Heading',
  subheading: 'Default Subheading',
  text: 'This is default text for the section.',
  button1Text: 'Získat nabídku',
  button2Text: 'Proč my?',
  priceText: 'Price',
  showArrow: true
};

export default MenuSection;
