import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Menu from './pages/Menu/Menu';
import WhyUs from './pages/WhyUs/WhyUs';
import Contact from './pages/Contact/Contact';
import { useEffect } from 'react';
import { analytics } from "./firebase";
import { logEvent } from "firebase/analytics";

function App() {
  useEffect(() => {
    logEvent(analytics, 'page_view')
  })

  return (
    <Router>
    <div>
      <Routes>
        <Route path="/" element={<Menu />} /> {/* Main Screen */}
        <Route path="/proc-my" element={<WhyUs />} />
        <Route path="/kontakt" element={<Contact />} />
      </Routes>
    </div>
  </Router>
  );
}

export default App;
