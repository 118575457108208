import React from 'react';
import './PriceItem.css'; // Make sure to create this CSS file for styling

const PriceItem = ({ name, price, description }) => {
    return (
        <div>
            <div className="price-item">
                <p className="price-name">{name}</p>

                <p className='price-text'>{price}</p>

            </div>
            {description ? <p className='itemDescription'>{description}</p> : <div></div>}
        </div>
    );
};

export default PriceItem;
